import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import SearchForm from "../components/SearchForm";
import SearchResults from "../components/SearchResults";
import useFilters, { FiltersWrapper } from "../contexts/FiltersWrapper";
import { TagsWrapper } from "../contexts/TagsWrapper";
import clsx from "clsx";

export const query = graphql`
  query SearchQuery {
    allSanityLieu(filter: { archive: { ne: true } }) {
      nodes {
        slug {
          current
        }
        title
        surface
        nature
        capacity
        type
        etage
        images: _rawImages(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;

const Search = ({ data }) => {
  const { nodes } = data.allSanityLieu;
  const { filters } = useFilters();
  // console.log(filters);
  const [step, setStep] = useState(1);

  // useEffect(() => {
  //   // if (Object.entries(filters).length > 0) setStep(2);
  // }, [filters]);

  return (
    <div className={clsx(step === 1 ? "pt-lg-" : "")}>
      <Seo
        pageTitle={"Search"}
        pageDescription={""}
        pageImage={""}
        template={`template-search ${
          Object.entries(filters).length > 0 ? "template-search-result" : ""
        }`}
        page={true}
      />
      <TagsWrapper>
        <FiltersWrapper>
          {step === 1 && <SearchForm input={nodes} setStep={setStep} />}
          {step === 2 && <SearchResults input={nodes} setStep={setStep} />}
        </FiltersWrapper>
      </TagsWrapper>
    </div>
  );
};

export default Search;
