import React from "react";
// import { Link } from "gatsby";
import SanityImage from "./SanityImage";
// import AnimateOnScroll from "./ui/AnimateOnScroll";
import clsx from "clsx";
import styled from "styled-components";
import useTags from "../contexts/TagsWrapper";
// import LinkFade from "./ui/TransitionLinks/LinkFade";
import { Link } from "gatsby";
import Video24h from "./Video24h";
import useDeviceDetect from "../hooks/useDeviceDetect";

const Wrapper = styled.article`
  &.archive {
    .bg-placeholder {
      // background: #7afb87 !important;
      background: #fff !important;
    }
    img {
      filter: grayscale(100%) saturate(50%);
      mix-blend-mode: multiply;
    }
  }
  figure {
    // transform: translateY(-50%);
    // top: 50%;
  }
`;

const LieuCard = ({ input, target }) => {
  const {
    slug,
    title,
    video,
    posterVideo,
    images,
    etage,
    surface,
    type,
    archive,
  } = input;
  const { tag } = useTags();
  // const breakpoints = useBreakpoint();
  const { isMobile } = useDeviceDetect();
  // console.log(archive);

  const isFiltering = tag !== "";

  let hide = false;
  if (isFiltering) {
    hide = true;
    if (tag === type.toLowerCase()) hide = false;
    if (tag === "archive" && archive) hide = false;
  }

  return (
    <Wrapper
      className={clsx(
        "lieux card relative",
        type.toLowerCase(),
        archive ? "archive" : "",
        hide ? "hidden" : ""
      )}>
      <Link
        to={`/lieu/${slug.current}`}
        className=''
        activeStyle={{}}
        target={target}>
        <div
          className='figure-ar overflow-hidden'
          style={{ aspectRatio: isMobile ? "unset" : "1.5 / 1" }}>
          {!archive && video ? (
            <Video24h
              video={video}
              poster={posterVideo ? posterVideo : images[0]}
            />
          ) : (
            <SanityImage input={images[0]} objectFit='cover' />
          )}
        </div>
        <div className='card-header absolute bottom-0 left-0 p-sm md:p-md text-sm text-white- lh1 mix-blend-difference- uppercase'>
          <ul className='flex'>
            <li className='pr-md flex'>
              <h2 className='uppercase'>{title}</h2>
              {archive && <span className='pl-xs'>[ARCHIVE]</span>}
            </li>
            <li className='pr-md'>{etage}</li>
            <li className='pr-md'>{surface}M2</li>
          </ul>
        </div>
      </Link>
    </Wrapper>
  );
};

export default LieuCard;
