import React, { useEffect, useState } from "react";
import useFilters from "../contexts/FiltersWrapper";
import { _localizeText } from "../core/utils";

const FormRowRadio = ({ name, input, onChange }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    if (value)
      onChange({
        name: name.toLowerCase(),
        value: value,
      });
  }, [value]);

  return (
    <div className='form-row mb-lg '>
      <div className='label uppercase'>{_localizeText(name)} :</div>
      <div className='flex flex-wrap flex-col md:flex-row'>
        {input.map((el, i) => (
          <div className='radio pr-sm' key={i}>
            <label className='cursor-pointer'>
              <input
                type={"radio"}
                id={`input-${el.toLowerCase()}`}
                name={name.toLowerCase()}
                value={el}
                // value={el}
                onChange={(e) => setValue(e.target.value)}
                checked={value === el ? true : false}
                readOnly
                className='text-gray'
              />
              <span className='cursor-pointer text-gray'>{el}</span>
              <span className='colon hidden-sm'>
                {i < input.length - 1 && ", "}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const FormRowCheckbox = ({ name, input, onChange }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    if (value)
      onChange({
        name: name.toLowerCase(),
        value: value,
      });
  }, [value]);

  return (
    <div className='form-row mb-lg '>
      <div className='label uppercase'>{_localizeText(name)} :</div>
      <div className='flex flex-wrap flex-col md:flex-row'>
        {input.map((el, i) => (
          <div className='checkbox pr-sm' key={i}>
            <label className='cursor-pointer'>
              <input
                type={"checkbox"}
                id={`input-${el.toLowerCase()}`}
                name={name.toLowerCase()}
                value={el}
                // value={el}
                onChange={(e) => setValue(e.target.value)}
                // checked={value === el ? true : false}
                // readOnly
                className='text-gray'
              />
              <span className='cursor-pointer value text-gray'>{el}</span>
              <span className='colon hidden-sm'>
                {i < input.length - 1 && ", "}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const FormRowNumber = ({ name, input, step, suffix, onChange }) => {
  const min =
    input.reduce(function (a, b) {
      return Math.min(parseFloat(a), parseFloat(b));
    }) || 0;
  const max =
    input.reduce(function (a, b) {
      return Math.max(parseFloat(a), parseFloat(b));
    }) || input.length;

  // console.log(name, min, max);
  // const [value, setValue] = useState(min || 0);
  const [value, setValue] = useState("");

  const _update = (val) => {
    console.log(val);
    if (val >= min && val <= max) setValue(val);
  };

  useEffect(() => {
    if (value)
      onChange({
        name: name.toLowerCase(),
        value: value,
      });
  }, [value]);
  // console.log(value);

  return (
    <div className='form-row form-row--number mb-lg'>
      <div className='label uppercase'>
        {_localizeText(name)} {suffix}:
      </div>
      <div className='flex '>
        <div
          role='button'
          className='cursor-pointer text-gray'
          onClick={() => _update(parseFloat(value || 0) - step)}>
          -
        </div>
        <input
          type='text'
          pattern='[0-9]*'
          id={`input-${name.toLowerCase()}`}
          name={name.toLowerCase()}
          min={min}
          max={max}
          value={value}
          placeholder='0'
          // defaultValue={min}
          className={value && value !== 0 ? "set" : "text-gray"}
          readOnly
        />
        <div
          role='button'
          className='cursor-pointer text-gray'
          onClick={() => _update(parseFloat(value || min) + step)}>
          +
        </div>
      </div>
    </div>
  );
};

const SearchForm = ({ input, setStep }) => {
  const [data, setData] = useState([]);
  const { dispatchFilter } = useFilters();
  console.log(input);
  const _getAllValuesFromKey = (key) => {
    return input
      .map((el) => {
        console.log(key, typeof el[key]);
        if (typeof el[key] === "string") return el[key].toUpperCase();
        else if (typeof el[key] === "object") {
          console.log({ el, key });
          return el && el[key]
            ? el[key].map((val) => val.toUpperCase() || "")
            : "";
        } else return el[key];
      })
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  const _getAllNatures = (key) => {
    const nature = input.map((el) => el.nature.map((_el) => _el.toUpperCase()));

    let jointArray = [];
    nature.forEach((array) => {
      jointArray = [...jointArray, ...array];
    });

    return [...new Set([...jointArray])];
  };

  const allNature = _getAllNatures("nature");
  // console.log(allNature);
  const allType = _getAllValuesFromKey("type");
  const allSurface = _getAllValuesFromKey("surface");
  const allCapacity = _getAllValuesFromKey("capacity");
  console.log(allCapacity);
  const _onSubmit = (e) => {
    e.preventDefault();
    // console.log("_onSubmit", data);
    dispatchFilter(data);
    setStep(2);
  };

  const _onInputChange = ({ name, value }) => {
    // console.log("_onInputChange", name, value);
    if (name === "nature") {
      if (data[name]) {
        // console.log(data[name]);
        if (data[name].includes(value)) {
          //remove from array
          setData((data) => ({
            [name]: data[name].filter((el) => el !== value),
          }));
        } else {
          setData((data) => ({
            [name]: [...data[name], value],
          }));
        }
      } else {
        setData((data) => ({
          [name]: [value],
        }));
      }

      // setData((data) => ({ ...data, [name, value] }));
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };
  // console.table(data);

  return (
    <form
      onSubmit={(e) => _onSubmit(e)}
      className='search-form text-lg p-sm py-xl md:px-md md:py-header-height'>
      <FormRowCheckbox
        name={"nature"}
        input={allNature}
        onChange={_onInputChange}
      />
      <FormRowNumber
        name={"surface"}
        input={allSurface}
        step={50}
        suffix='M2'
        onChange={_onInputChange}
      />
      <FormRowRadio name={"Type"} input={allType} onChange={_onInputChange} />

      <FormRowNumber
        name={"capacity"}
        input={allCapacity}
        step={50}
        suffix=''
        onChange={_onInputChange}
      />
      <div className='form-row'>
        <button type='submit' className='button outline text-sm'>
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchForm;
