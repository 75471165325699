import React, { createContext, useContext, useEffect, useState } from "react";

const TagsContext = createContext();
const initialTagsState = "";

export const TagsWrapper = ({ children }) => {
  // const [Tags, dispatchFilter] = useReducer(reducer, initialTagsState);
  const [tag, dispatchTag] = useState(initialTagsState);
  // console.log("TagsWrapper", filter)

  useEffect(() => {
    if (tag) {
      window.scrollTo({ top: 0 });
    }
  }, [tag]);

  return (
    <TagsContext.Provider value={{ tag, dispatchTag }}>
      {children}
    </TagsContext.Provider>
  );
};
export default function useTags() {
  return useContext(TagsContext);
}
