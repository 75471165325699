import { Link } from "gatsby";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import React from "react";
import useFilters from "../contexts/FiltersWrapper";
import LieuCard from "./LieuCard";

const SearchResults = ({ input, setStep }) => {
  const { settings } = useSiteMetadata();
  const { filters, dispatchFilter } = useFilters();
  const keys = Object.keys(filters);

  const selection = input.filter((node) => {
    let isIn = true;
    keys.forEach((key) => {
      if (key === "surface" || key === "capacity") {
        if (node[key] < filters[key]) isIn = false;
      } else {
        // console.log(key, node[key], filters[key]);
        if (typeof node[key] === "object") {
          node[key].forEach((el) => {
            if (!filters[key].includes(el.toUpperCase())) isIn = false;
          });
        } else if (node[key] !== filters[key]) isIn = false;
      }
    });
    return isIn;
  });

  const _reset = () => {
    dispatchFilter("");
    setStep(1);
  };

  return (
    <div className='search-result'>
      {selection.length > 0 && (
        <div className='grid md:grid-cols-2'>
          <div className='left order-2 md:order-1 '>
            {selection.map((node, i) => (
              <LieuCard key={i} input={node} target={"_blank"} />
            ))}
          </div>
          <div className='right  order-1 md:order-2'>
            <div className='sticky top-0 p-md-ajusted pt-header-height md:pt-md-ajusted  text-lg'>
              <ul className='mb-lg'>
                {keys.map((key, i) => (
                  <li
                    key={i}
                    className='uppercase'>{`${key}: ${filters[key]}`}</li>
                ))}
              </ul>
              <button className='text-md ' onClick={_reset}>
                ← Back to search
              </button>
            </div>
          </div>
        </div>
      )}

      {selection.length === 0 && (
        <div className='grid h-screen justify-center content-center text-center p-md'>
          <p className=''>
            Aucun de nos lieux actuels ne correspond à votre recherche mais nous
            <br />
            pouvons faire une recherche spécifique ou créer un lieu virtuel
            <br />
            sur-mesure pour vous. Écrivez-nous à contact@forest.paris
          </p>
          <ul className='buttons flex justify-center'>
            <li className='px-xs'>
              <a
                href={`mailto:${settings.contact}`}
                className='button outline  inline-block'>
                Email
              </a>
            </li>
            <li className='px-xs'>
              <button className='button outline' onClick={_reset}>
                Back to search
              </button>
              {/* <Link to='/search' className='button outline  inline-block'>
                Back to search
              </Link> */}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
