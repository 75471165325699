import clsx from "clsx";
import { debugLog } from "../core/utils";
import React, { useEffect, useRef, useState } from "react";
import useSunriseSunset from "../contexts/SunriseSunsetContext";
import useDeviceDetect from "../hooks/useDeviceDetect";
import SanityImage from "./SanityImage";

/*
  Il y a, par lieu, une vidéo de 10 secondes où on va tester des transitions de 2 secondes  (au clic sur le soleil ou selon l’horaire de visite du site) :
  0sec.Nuit
  2sec.Matin
  4. Midi
  6 Après-midi
  8. Soir
  10. Nuit de nouveau
  */

const Video24h = ({ video, poster }) => {
  // console.log(poster);
  const videoRef = useRef();
  const [duration, setDuration] = useState(11);
  const { index, moments } = useSunriseSunset();
  const [show, setShow] = useState(false);
  // console.log("moments", moments);
  const { isMobile } = useDeviceDetect();
  // debugLog(video);
  useEffect(() => {
    // console.log("on enter");
    // console.log(moments);
    // debugLog("on enter");

    videoRef.current.addEventListener("durationchange", _onDurationChange);
    videoRef.current.addEventListener("timeupdate", _onTimeUpdate);
    videoRef.current.addEventListener("seeked", _onSeeked);
    videoRef.current.addEventListener("canplay", _onCanPlay);
    videoRef.current.addEventListener("ended", _onEnded);

    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener(
          "durationchange",
          _onDurationChange
        );
        videoRef.current.removeEventListener("timeupdate", _onTimeUpdate);
        videoRef.current.removeEventListener("seeked", _onSeeked);
        videoRef.current.removeEventListener("canplay", _onCanPlay);
        videoRef.current.removeEventListener("ended", _onEnded);
      }
    };
  }, [index, duration]);

  // useEffect(() => {
  //   if (isMobile) setShow(true);
  // }, [isMobile]);

  const _onCanPlay = () => {
    // console.log("_onCanPlay");
    if (!videoRef.current) return;
    const nextCue = _getNextCue();
    videoRef.current.currentTime = nextCue;
    videoRef.current.removeEventListener("canplay", _onCanPlay);
  };

  const _onSeeked = () => {
    // console.log("_onSeeked");
    setShow(true);
  };

  const _onDurationChange = () => {
    console.log(videoRef.current.duration);
    setDuration(videoRef.current.duration);
    setShow(true);
  };

  const _onTimeUpdate = () => {
    const nextCue = _getNextCue();
    // console.log("nextCue", nextCue, videoRef.current.currentTime);
    if (videoRef.current && videoRef.current.currentTime >= nextCue) {
      // console.log(videoRef.current.currentTime);
      // videoRef.current.currentTime = max;
      if (!videoRef.current.paused) videoRef.current.pause();
      setShow(true);
    }
  };

  const _onEnded = () => {
    // console.log("ended");
    videoRef.current.currentTime = 0;
  };

  const _getNextCue = () => {
    // console.log("_getNextCue", index, duration);
    const parts = duration / moments.length;
    // console.log(moments.length, index);
    const nextCue = parts * index + parts;
    // console.log(nextCue);
    return nextCue;
  };

  useEffect(() => {
    // if (isMobile) return;
    // if (index === 0) videoRef.current.currentTime = 0;
    // console.log(videoRef.current.paused);
    var playPromise = videoRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
          // video.pause();
        })
        .catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }

    // if (videoRef.current.paused) videoRef.current.play();
  }, [index]);

  return (
    <div
      className={clsx(
        "video-24h overflow-hidden"
        // show ? "opacity-100" : "opacity-0"
      )}
      style={{ aspectRatio: "1.5 / 1" }}>
      <video
        // controls={false}
        preload='none'
        muted
        playsInline
        // poster={poster.asset.url}
        className='h-full w-full object-cover lazy'
        ref={videoRef}>
        <source data-src={video.asset.url} type={video.asset.mimeType} />
        Your browser does not support the video tag.
      </video>
      <div
        className={clsx(
          "poster absolute top-0 left-0 h-full w-full object-cover transition-opacity duration-1000 delay-1000",
          show ? "opacity-0" : "opacity-100"
        )}>
        <SanityImage input={poster} objectFit='cover' />
      </div>
      {/* <div className='dbg absolute right-0 bottom-0 p-md'>{index}</div> */}
    </div>
  );
};

export default Video24h;
